import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { UseIntervalExample } from './use-interval.example.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "useinterval"
    }}>{`useInterval`}</h1>
    <p>{`Setup an interval playing nicely with the hooks api. The implementation is
shamelessly copied from
`}<a parentName="p" {...{
        "href": "https://overreacted.io/making-setinterval-declarative-with-react-hooks/"
      }}>{`Dan Abramov`}</a>{`.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { useState } from 'react';
import { useInterval } from '@fransvilhelm/hooks';

const Counter = () => {
  const [run, setRun] = useState(false);
  const [count, setCount] = useState(0);

  useInterval(() => setCount(count + 1), run ? 1000 : null);

  return (
    <div>
      <button onClick={() => setRun(!run)}>{run ? 'Pause' : 'Play'}</button>
      <output>{count}</output>
    </div>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<UseIntervalExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      UseIntervalExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UseIntervalExample mdxType="UseIntervalExample" />
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`useInterval`}</inlineCode>{` accepts two parameters – the `}<inlineCode parentName="p">{`callback`}</inlineCode>{` to run on the interval and
a number representing the `}<inlineCode parentName="p">{`delay`}</inlineCode>{`. The delay might be `}<inlineCode parentName="p">{`null`}</inlineCode>{` in order to stop
the interval.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`callback`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`function`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Callback to run on every interval`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`delay`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number `}{`|`}{` null`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The time between intervals. Use null to stop the interval`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <p><inlineCode parentName="p">{`useInterval`}</inlineCode>{` nothing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      